import React from "react"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import rehypeSlug from "rehype-slug-custom-id"
import { graphql } from "gatsby"
import { Container, Grid } from "@mui/material"
import Layout from "../components/Layout/Layout"
import { Helmet } from "react-helmet"

const Awesome = ({ data }) => {
  const openSourceList = data.openSourceList.nodes
  const awesomeData =
    data.allGithubData.edges[0].node.data.repository.readme.text

  return (
    <Layout>
      <Helmet>
        <title> Awesome - Cassandra Link </title>
        <meta
          name="description"
          content="Explore the awesome resources on Cassandra Link - Your Source for Cassandra Resources"
        />
        <meta property="og:title" content="Cassandra Link - Awesome" />
        <meta
          property="og:description"
          content="Explore the awesome resources on Cassandra Link - Your Source for Cassandra Resources"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://cassandra.link/awesome" />
        <meta property="og:image" content="../../images/Logo.svg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Cassandra Link - Awesome" />
        <meta
          name="twitter:description"
          content="Explore the awesome resources on Cassandra Link - Your Source for Cassandra Resources"
        />
        <meta name="twitter:image" content="../../images/Logo.svg" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://cassandra.link/",
            "@type": "WebPage",
            name: "Awesome - Cassandra Link",
            keywords: "Cassandra Link, Awesome, Cassandra",
            author: {
              "@type": "Organization",
              name: "Cassandra Link",
            },
          })}
        </script>
        {/* Open Graph */}
        <meta property="og:title" content={"Awesome - Cassandra Link"} />

        {/* Other meta tags you may consider adding */}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      </Helmet>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item md={12}>
            <ReactMarkdown
              children={awesomeData}
              rehypePlugins={[rehypeSlug, rehypeRaw]}
            />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default Awesome

export const pageQuery = graphql`
  query OpenSource {
    openSourceList: allCassandraLinks(
      filter: { domain_name: { regex: "/github.com/" }, content: { ne: null } }
      sort: { fields: wallabag_created_at, order: DESC }
      limit: 12
    ) {
      nodes {
        alternative_id
        title
        origin_url
        url
        wallabag_created_at

        published_at

        language
        reading_time
        domain_name
        preview_picture
        tags
      }
    }
    allGithubData {
      edges {
        node {
          data {
            repository {
              readme {
                text
              }
            }
          }
        }
      }
    }
  }
`
